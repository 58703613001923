.login {
  height: 100vh;
  background-color: #edf0f3;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login .login__card {
  max-width: 450px;
  box-shadow: 0 4px 16px 4px rgba(177, 177, 177, 0.52);
  background-color: white;
  justify-content: center;
  display: flex;

  @media screen {
    @media (max-width: @screen-xs) {
      height: 100vh;
      max-width: none;
      width: 100%;
    }
  }
}

.login .login__logo {
  //margin-top: 50px;
  margin-bottom: 24px;
  width: 100%;
}

.login .login__form-button {
  width: 100%;
  align-self: center;
  margin-top: 16px;
}

@hack: true;@import "/home/runner/work/workwy/workwy/node_modules/antd/lib/style/themes/index.less";@primary-color: #113269;