.edit-job {
  height: 100%;
  padding-bottom: 16px;
}

.edit-job .edit-job__map {
  width: 100%;
  height: 150px;
  margin-bottom: 15px;
}
@hack: true;@import "/home/runner/work/workwy/workwy/node_modules/antd/lib/style/themes/index.less";@primary-color: #113269;