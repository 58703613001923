
.job-locations {
  height: 100%;
  padding-bottom: 16px;
}

.job-locations .job-locations__container {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.job-locations .job-locations__location-row {
  cursor: pointer;
}

.job-locations .job-locations__select {
  width: 100%;
}

@hack: true;@import "/home/runner/work/workwy/workwy/node_modules/antd/lib/style/themes/index.less";@primary-color: #113269;