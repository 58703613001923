.edit-worker {
  height: 100%;
  padding-bottom: 16px;
}

.edit-worker .edit-worker__select {
  width: 100%;
}

.edit-worker .edit-worker__tags-row {
  padding: 4px 0;
}

.edit-worker .edit-worker__tag {
  margin: 4px 4px 0 0;
}
@hack: true;@import "/home/runner/work/workwy/workwy/node_modules/antd/lib/style/themes/index.less";@primary-color: #113269;