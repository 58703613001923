
.group-workers {
  height: 100%;
  padding-bottom: 16px;
}

.group-workers .group-workers__container {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.group-workers .group-workers__location-row {
  cursor: pointer;
}

.group-workers .group-workers__select {
  width: 100%;
}

@hack: true;@import "/home/runner/work/workwy/workwy/node_modules/antd/lib/style/themes/index.less";@primary-color: #113269;