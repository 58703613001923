.calendar-days {
  align-items: center;
  width: 100%;
  justify-content: space-evenly;
  height: 24px;
}

.calendar-days .calendar-days__day-container {
  width: 10px;
  color: #d9d9d9;
}

.calendar-days .calendar-days__day-container-active{
  color: @primary-color;
}
@hack: true;@import "/home/runner/work/workwy/workwy/node_modules/antd/lib/style/themes/index.less";@primary-color: #113269;