.subpage-layout {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.subpage-layout .subpage-layout__header {
  display: flex;
  flex-direction: column;
  height: 6rem;
  margin-bottom: 1rem;
}

.subpage-layout .subpage-layout__title {
  font-size: 35px;

  @media screen {
    @media (max-width: @screen-xs) {
      font-size: 25px;
    }
  }
}

.subpage-layout .subpage-layout__back-button-row {
  height: 32px;
  justify-content: left;
  align-items: center;
}

.subpage-layout .subpage-layout__navigation-row {
  height: 32px;
  justify-content: left;
  align-items: center;
  margin-top: 8px;
}
@hack: true;@import "/home/runner/work/workwy/workwy/node_modules/antd/lib/style/themes/index.less";@primary-color: #113269;