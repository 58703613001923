.task-group-form-element {}

.task-group-form-element .task-group-form-element__number-Input {
  width: 100%
}

.task-group-form-element .task-group-form-element__remove-group-column {
  align-items: center;
  justify-content: center;
  display: flex;
  height: 32px;
}
@hack: true;@import "/home/runner/work/workwy/workwy/node_modules/antd/lib/style/themes/index.less";@primary-color: #113269;