.app-layout {
  background: @body-background;
  max-width: 100%;
}

.app-layout .app-layout__show-above-md {
  @media screen {
    @media (min-width: @screen-md) {
      display: none;
    }
  }
}

.app-layout .app-layout__hide-below-md {
  @media screen {
    @media (max-width: @screen-md) {
      display: none;
    }
  }
}

.app-layout__logo {
  padding: 16px;
  float: left;
  width: 100%;
}

.app-layout_drawer .ant-drawer-wrapper-body {
  background-color: @menu-dark-bg;
}

.app-layout_drawer .ant-drawer-body {
  padding: 0;
}

.app-layout_drawer .ant-drawer-header {
  background-color: @menu-dark-bg;
  border: none;
  padding: @margin-xs;
}

.app-layout .app-layout__top-header {
  background: @body-background;
  display: flex;
  padding: 0;
  align-items: center;
}

.app-layout .app-layout__right-menu {
  margin-left: auto;
}

.app-layout .app-layout__header-mobile-logo {
  height: 100%;
  line-height: 64px;
  padding-left: 24px;
  cursor: pointer;
}

.app-layout .app-layout__menu-toggle {
  font-size: 20px;
  align-items: center;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

.app-layout .app-layout__menu-toggle:hover {
  color: @primary-color;
}

.app-layout .app-layout__background {
  background: @body-background;
  margin: 16px;
  padding: 16px;
  height: 100%;
}

.app-layout .app-layout__form-button {
  margin-top: 16px;
}

.app-layout .app-layout__columns-arrangement {
  display: flex;
  justify-content: flex-start;
  height: 100%;
  align-items: stretch;
}

.app-layout .app-layout__last-row {
  display: flex;
  justify-content: space-between;
}

.app-layout .app-layout__form {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@hack: true;@import "/home/runner/work/workwy/workwy/node_modules/antd/lib/style/themes/index.less";@primary-color: #113269;