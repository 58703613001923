.create-user {
  height: 100%;
  padding-bottom: 16px;
}

.create-user .create-user__form {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.create-user .create-user__last-row {
  display: flex;
  justify-content: space-between;
}

.create-user .create-user__form-row {
  justify-content: flex-start;
}

@hack: true;@import "/home/runner/work/workwy/workwy/node_modules/antd/lib/style/themes/index.less";@primary-color: #113269;