.calendar-input {
  width: 100%;
}

.calendar-input .calendar-input__range-row {
  width: 100%;
  padding: 0 0 30px 0;
}

.calendar-input .calendar-input__day-time-row {
  width: 100%;
  align-items: baseline;
  max-height: 40px;
}

.calendar-input .calendar-input__day-select {
  width: 100%;
}

.calendar-input .calendar-input__remove-range-column {
  align-items: center;
  justify-content: center;
  display: flex;
}
@hack: true;@import "/home/runner/work/workwy/workwy/node_modules/antd/lib/style/themes/index.less";@primary-color: #113269;