.list-layout {
}

.list-layout .list-layout__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 8rem;
}

.list-layout .list-layout__button {
  height: 2rem;
  width: 250px;
}

.list-layout .list-layout__list-row {
  cursor: pointer;
}
@hack: true;@import "/home/runner/work/workwy/workwy/node_modules/antd/lib/style/themes/index.less";@primary-color: #113269;